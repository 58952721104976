import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import CustomToggle from '../helpers/customToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowSize } from '../hooks/useWindowSize'
import { PersonCard } from '.'
import useCourseTemplate from '../hooks/useCourseTemplate'
import SortTeachers from '../helpers/sortTeachers'
import ButtonInscription from './ButtonInscription'
            
const CourseDetails = ({ features, date, dateEndInscr, link, linkContacto, externalLink }) => {
  const [key, setKey] = useState('description')
  const [width] = useWindowSize()
  const [valor, setValor] = useState(1)
  const [active, setActive] = useState(false)
  const [activeCard, setActiveCard] = useState(false)
  
  const dataCoursesTemplate = useCourseTemplate()

  // Current Date for Credit Card Validation
  var currentDate = new Date()

  useEffect(() => {
    // get data from GitHub api
    fetch('https://dolarapi.com/v1/dolares')
      .then((response) => response.json()) // parse JSON from request 
      .catch(err => {
        console.log("Error response ", err)
      })
      .then((resultData) => {
        resultData.map((item) => {
          if (item.casa === 'oficial') {
            setValor(item.compra)
          }
        })
      }) // set data for the number of stars
      .catch(err => {
        console.log("Error resultData ", err);
      })
  }, [])

  const dollarPrice = (
    <div>
      <h5 className='font-weight-bold'>Equivalente a USD  {' '}{parseInt(features?.priceLists?.price[0]?.price / parseFloat(valor))}</h5>
    </div>
  )
  const dollarEspPrice = (
    <div>
      <h5 className='font-weight-bold'>Equivalente a USD  {' '}{parseInt(features?.priceLists?.specialPrice?.price?.price / parseFloat(valor))}</h5>
   </div>
  )
  
  const foreignersPay = (
    <div className="text-secondary">
      <h6>Extranjeros podrán abonar con tarjetas de crédito Visa o Mastercard en 1 pago.</h6>
      <h5>(al tipo de cambio de referencia Com. A3500 BCRA actualización diaria)</h5>
    </div>
  )

  const generalPrice = features?.priceLists?.price[0]
  const otherPrices = features?.priceLists?.price.slice(1)

  const specialTeachersNotOrdened = features?.teachers?.filter(
    (teacher) => teacher.teacherDetails
  )

  const specialTeachers = SortTeachers(specialTeachersNotOrdened)

  const teachersNotOrdened = features?.teachers?.filter(
    (teacher) => !teacher.teacherDetails
  )
  const teachers = SortTeachers(teachersNotOrdened)

  const agreements =
    dataCoursesTemplate?.strapiCourseFjsTemplate?.agreementsPage
  const cardAgreements = features?.cardAgreements?.agreement
  const cardAgreementsTemplate =
    dataCoursesTemplate?.strapiCourseFjsTemplate?.cardAgreements?.agreement

    const formatterPeso = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0
    })

  function slugify(text) {
    return text
      ?.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  let mapResult = []

  function addResult(obj) {    
    var slug_bank = slugify(obj.bank ?? 'undefined')
    var slug_card = slugify(obj.card)
    var slug_interest = slugify(obj.interest ?? '')
    var slug_validity = slugify(obj.validity)
    obj.slug = `${slug_bank}_${slug_card}_${slug_interest}_${slug_validity}`
    if (mapResult.length > 0) {
      var search = mapResult.find((item) => item.slug == obj.slug)
      if (search) {
        var fees = search.fee.split(',')
        fees.find((fee) => fee == obj.fee) ?? fees.push(obj.fee)
        search.fee = fees.join(' ,')
      } else {
        mapResult.push(obj)
      }
    } else {
      mapResult.push(obj)
    }
  }

  function sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]
      var y = b[key]
      return x < y ? -1 : x > y ? 1 : 0
    })
  }

  cardAgreementsTemplate?.forEach(addResult)
  cardAgreements?.forEach(addResult)

  mapResult = sort_by_key(mapResult, 'bank')

  let columns = 12
  if (specialTeachers.length === 2) columns = 6
  if (specialTeachers.length >= 3) columns = 4

  const courseDesktop = (
    <div className="tabs-component pb-5 container">
      <div className="py-4">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {features.description && (
            <Tab eventKey="description" title="Descripción">
              <h6>
                <b>DIRIGIDO A</b>
              </h6>
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                // children={features.description.addressedTo}
                children={features.description.addressedTo?.replaceAll(
                  '\n',
                  '\n \n'
                )}
                className="publication-content-markdown"
              />
              <h6>
                <b>DESCRIPCIÓN</b>
              </h6>
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                // children={features.description.description}
                children={features.description.description?.replaceAll(
                  '\n',
                  '\n \n'
                )}
                className="publication-content-markdown"
              />
            </Tab>
          )}
          {features.schedule && (
            <Tab eventKey="schedule" title="Cronograma">
              <h6>
                <b>INICIO</b>
              </h6>
              <p>{date}</p>
              <h6>
                <b>DURACIÓN</b>
              </h6>
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                children={features.schedule.duration}
                //children={features.schedule.duration?.replaceAll('\n', '\n \n')}
                className="publication-content-markdown"
              />
            </Tab>
          )}
          {features.objectives && (
            <Tab eventKey="objectives" title="Objetivos">
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                // children={features.objectives.objectives}
                children={features.objectives.objectives?.replaceAll(
                  '\n',
                  '\n \n'
                )}
                className="publication-content-markdown"
              />
            </Tab>
          )}
          {features.contents && (
            <Tab eventKey="contents" title="Contenidos">
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                children={features.contents.contents?.replaceAll('\n', '\n \n')}
                className="publication-content-markdown"
              />
            </Tab>
          )}
          {features.teachers && (
            <Tab eventKey="teachers" title="Docentes">
              <div className="row">
                {specialTeachers.length > 0 &&
                  specialTeachers.map((teacher, idx) => (
                    <PersonCard
                      key={`teacher-${idx}`}
                      professional={teacher}
                      columns={columns}
                    />
                  ))}
                {teachers.length > 0 &&
                  teachers.map((teacher, idx) => (
                    <p
                      key={`teacher-${idx}`}
                      className="col-12"
                    >{`${teacher?.name} (${teacher?.role})`}</p>
                  ))}
              </div>
            </Tab>
          )}
          
          {features.priceLists && (
            <Tab eventKey="price" title="Precio">
              
              {/* Precio Especial de los Cursos - Validando fecha de inicio y fin */}
              {features.priceLists.specialPrice &&
              features.priceLists.specialPrice.expiration >= new Date().toISOString() &&
              (features.priceLists.specialPrice.price.startDate == null ||
              features.priceLists.specialPrice.price.startDate < new Date().toISOString()) ? 
              (
                <p>
                  {features.priceLists.specialPrice.price.description.substring(3)}{' '}
                  - <b className='h4 font-weight-bold'>{formatterPeso.format(features.priceLists.specialPrice.price.price)}</b>
                  {dollarEspPrice}
                </p>
              ) : (
                <>
                  {/* Precio principal del curso - Validando fecha de inicio */}
                  {generalPrice?.startDate == null ||
                  generalPrice?.startDate < new Date().toISOString() ?
                    <p>
                      {generalPrice?.description.substring(3)}{' '}
                      - <b className='h4 font-weight-bold'>{formatterPeso.format(generalPrice?.price)}</b>
                    </p>
                    : null
                  }

                  {/* Precio en dolares */}
                  {dollarPrice}

                  {/* Otros precios del curso */}
                  {otherPrices.length > 0 && (
                    <>
                      <p
                        onClick={() => setActive(!active)}
                        className="btn btn-sm btn-outline"
                      >
                        Promociones y descuentos vigentes
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-down']}
                          size="sm"
                          rotation={active ? 180 : null}
                          className="ml-2"
                        />
                      </p>
                      <div
                        className={` ${
                          active
                            ? 'customAccordeon_content-active'
                            : 'customAccordeon_content-hidden'
                        }`}
                      >
                        {/* Validando fecha de inicio y mostrandolos */}
                        {otherPrices.map((price, idx) => (
                          price.startDate == null ||
                          price?.startDate < new Date().toISOString() ?
                            <p key={`price-${idx}`}>
                              {price.description.substring(3)}{' '}
                              - <b className='h6 font-weight-bold'>{formatterPeso.format(price.price)}</b>
                            </p>
                          : null
                        ))}

                        {!features?.priceLists?.PageLists ? (
                          <Link
                            to={'/' + agreements?.fjs_page?.slug}
                            className="btn btn-sm btn-outline mb-4"
                          >
                            {agreements?.name}
                          </Link>
                        ) : (
                          features?.priceLists?.PageLists?.name && (
                            <Link
                              to={
                                '/' +
                                features?.priceLists?.PageLists?.fjs_page?.slug
                              }
                              className="btn btn-sm btn-outline mb-4"
                            >
                              {features?.priceLists?.PageLists?.name}
                            </Link>
                          )
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
              <p
                onClick={() => setActiveCard(!activeCard)}
                className="btn btn-sm btn-outline"
              >
                Promociones con Tarjeta de Crédito
                <FontAwesomeIcon
                  icon={['fas', 'chevron-down']}
                  size="sm"
                  rotation={activeCard ? 180 : null}
                  className="ml-2"
                />
              </p>
              {cardAgreements === undefined ? (
                <div
                  className={` ${
                    activeCard
                      ? 'customAccordeon_content-active mb-4'
                      : 'customAccordeon_content-hidden mb-4'
                  }`}
                >
                  {cardAgreementsTemplate?.map((item, idx) => {
                    // <p key={idx} className='mb-2'><strong>{item?.bank}</strong> {item?.fee} pagos {item?.interest.toLowerCase()} <strong>{item?.card}</strong> {item?.validity && item.validity !== "-" ? `(Vigente hasta ${item?.validity})` : ""} </p>
                    
                    var dateValidity = new Date(item.validity.split('-').reverse().join('-'))

                    return (
                      (item.validity === "-" || dateValidity.getTime() > currentDate.getTime()) &&    
                        <p key={idx} className="mb-2">
                          <strong>{item?.bank}</strong> {item?.fee} pagos{' '}
                          {item?.interest.toLowerCase()}{' '}
                          <strong>{item?.card}</strong>
                        </p>
                    )
                  })}
                  <p className="mb-2">
                    1 pago sin interes <strong>Pago Facil</strong>
                  </p>
                </div>
              ) : (
                <div
                  className={` ${
                    activeCard
                      ? 'customAccordeon_content-active mb-4'
                      : 'customAccordeon_content-hidden mb-4'
                  }`}
                >
                  {mapResult?.map((item, idx) => {
                    // <p key={idx} className='mb-2'><strong>{item?.bank}</strong> {item?.fee} pagos {item?.interest.toLowerCase()} <strong>{item?.card}</strong> {item?.validity && item.validity !== "-" ? `(Promoción válida hasta ${item?.validity})` : ""} </p>
                    
                    var dateValidity = new Date(item.validity.split('-').reverse().join('-'))
                    
                    // console.log(item.validity)
                    // console.log( dateValidity)
                    // console.log( "Actual! " + currentDate )
                    // console.log(dateValidity.getTime() > currentDate.getTime());
                    // console.log( item.validity === "-")  

                    return (
                      (item.validity === "-" || dateValidity.getTime() > currentDate.getTime()) &&                
                        <p key={idx} className="mb-2">
                          <strong>{item?.bank}</strong> {item?.fee} pagos{' '}
                          {item?.interest.toLowerCase()}{' '}
                          <strong>{item?.card}</strong>
                        </p>
                    )
                  })}
                  <p className="mb-2">
                    1 pago sin interes <strong>Pago Facil</strong>
                  </p>
                </div>
              )}

              {/* Boton de inscripcion/contacto */}
              <ButtonInscription
                dateEndInscr={dateEndInscr} link={link} linkContacto={linkContacto} externalLink={externalLink}
              />

              {/* medios de pago para extranjeros */}
              {foreignersPay}

              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                children={features.priceLists.content}
                className="publication-content-markdown mt-2"
              />
            </Tab>
          )}
          {features.moreInformation && (
            <Tab eventKey="moreInformation" title="Información">
              <h6>
                <b>REQUISITOS DE APROBACIÓN</b>
              </h6>
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                // children={features.moreInformation.requirements}
                children={features.moreInformation.requirements?.replaceAll(
                  '\n',
                  '\n \n'
                )}
                className="publication-content-markdown"
              />
              <h6>
                <b>LUGAR</b>
              </h6>
              <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                // children={features.moreInformation.place}
                children={features.moreInformation.place?.replaceAll(
                  '\n',
                  '\n \n'
                )}
                className="publication-content-markdown"
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  )
  const courseMobile = (
    <div className="container">
      {features.description && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={1}>
                <p className="mb-0 text-left">Descripción</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <h6>
                  <b>DIRIGIDO A</b>
                </h6>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.description.addressedTo}
                  className="publication-content-markdown"
                />
                <h6>
                  <b>DESCRIPCIÓN</b>
                </h6>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.description.description}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      {features.schedule && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={2}>
                <p className="mb-0 text-left">Cronograma</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <h6>
                  <b>INICIO</b>
                </h6>
                <p>{date}</p>
                <h6>
                  <b>DURACIÓN</b>
                </h6>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.schedule.duration}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}

      {features.objectives && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={3}>
                <p className="mb-0 text-left">Objetivos</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.objectives.objectives}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}

      {features.contents && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={4}>
                <p className="mb-0 text-left">Contenidos</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.contents.contents}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}

      {features.teachers && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={5}>
                <p className="mb-0 text-left">Teachers</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <div className="row">
                  {specialTeachers.length > 0 &&
                    specialTeachers.map((teacher, idx) => (
                      <PersonCard
                        key={`teacher-${idx}`}
                        professional={teacher}
                        columns={12}
                      />
                    ))}
                  {teachers.length > 0 &&
                    teachers.map((teacher, idx) => (
                      <p
                        key={`teacher-${idx}`}
                        className="col-12"
                      >{`${teacher?.name}, (${teacher?.role})`}</p>
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}

      {features.priceLists && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={6}>
                <p className="mb-0 text-left">Precio</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                {features.priceLists.specialPrice &&
                features.priceLists.specialPrice.expiration >=
                  new Date().toISOString() ? (
                  <p>
                    {features.priceLists.specialPrice.price.description.substring(
                      3
                    )}{' '}
                    - <b className='h5 font-weight-bold'>{formatterPeso.format(features.priceLists.specialPrice.price.price)}</b>
                    {dollarEspPrice}
                  </p>

                ) : (
                  <>
                    <p>
                      {generalPrice?.description.substring(3)} -{' '}
                      <b className='h4 font-weight-bold'>{formatterPeso.format(generalPrice.price)}</b>
                    </p>
                    {dollarPrice}
                    {otherPrices.length > 0 && (
                      <>
                        <p
                          onClick={() => setActive(!active)}
                          className="btn btn-sm customAccordeon_label"
                        >
                          Promociones y descuentos vigentes
                          <FontAwesomeIcon
                            icon={['fas', 'chevron-down']}
                            size="sm"
                            rotation={active ? 180 : null}
                            className="ml-2"
                          />
                        </p>
                        <div
                          className={` ${
                            active
                              ? 'customAccordeon_content-active'
                              : 'customAccordeon_content-hidden'
                          }`}
                        >
                          {otherPrices.map((price, idx) => (
                            <p key={`price-${idx}`}>
                              {price.description.substring(3)} -{' '}
                              <b className='h6 font-weight-bold'>{formatterPeso.format(price.price)}</b>
                            </p>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}

                <p
                  onClick={() => setActiveCard(!activeCard)}
                  className="btn btn-sm customAccordeon_label"
                >
                  Promociones con Tarjeta de Crédito
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-down']}
                    size="sm"
                    rotation={activeCard ? 180 : null}
                    className="ml-2"
                  />
                </p>
                {cardAgreements === undefined ? (
                  <div
                    className={` ${
                      activeCard
                        ? 'customAccordeon_content-active mb-4'
                        : 'customAccordeon_content-hidden mb-4'
                    }`}
                  >
                    {cardAgreementsTemplate?.map((item, idx) => {
                      // <p key={idx} className='mb-4'><strong>{item?.bank}</strong> {item?.fee} pagos {item?.interes} <strong>{item?.card}</strong> {item?.validity && item.validity !== "-" ?  `(Vigente hasta ${item?.validity})` : ""} </p>
                      
                      var dateValidity = new Date(item.validity.split('-').reverse().join('-'))

                      return (
                        (item.validity === "-" || dateValidity.getTime() > currentDate.getTime()) && 
                          <p key={idx} className="mb-4">
                            <strong>{item?.bank}</strong> {item?.fee} pagos{' '}
                            {item?.interes} <strong>{item?.card}</strong>
                          </p>
                      )
                    })}
                    <p className="mb-2">
                      1 pago sin interes <strong>Pago Facil</strong>
                    </p>
                  </div>
                ) : (
                  <div
                    className={` ${
                      activeCard
                        ? 'customAccordeon_content-active mb-4'
                        : 'customAccordeon_content-hidden mb-4'
                    }`}
                  >
                    {mapResult?.map((item, idx) => {
                      // <p key={idx} className='mb-4'><strong>{item?.bank}</strong> {item?.fee} pagos {item?.interes} <strong>{item?.card}</strong> {item?.validity && item.validity !== "-" ? `(Promoción válida hasta ${item?.validity})` : ""} </p>
                      
                      var dateValidity = new Date(item.validity.split('-').reverse().join('-'))

                      return (
                        (item.validity === "-" || dateValidity.getTime() > currentDate.getTime()) && 
                          <p key={idx} className="mb-4">
                            <strong>{item?.bank}</strong> {item?.fee} pagos{' '}
                            {item?.interes} <strong>{item?.card}</strong>
                          </p>
                      )
                    })}
                    <p className="mb-2">
                      1 pago sin interes <strong>Pago Facil</strong>
                    </p>
                  </div>
                )}
                
              {/* Boton de inscripcion/contacto */}
              <ButtonInscription
                dateEndInscr={dateEndInscr} link={link} linkContacto={linkContacto} externalLink={externalLink}
              />

              {/* medios de pago para extranjeros */}
              {foreignersPay}

                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.priceLists.content}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      {features.moreInformation && (
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomToggle eventKey={7}>
                <p className="mb-0 text-left">Información</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <h6>
                  <b>REQUISITOS DE APROBACIÓN</b>
                </h6>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.moreInformation.requirements}
                  className="publication-content-markdown"
                />
                <h6>
                  <b>LUGAR</b>
                </h6>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={features.moreInformation.place}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
    </div>
  )

  return (
    <>
      {width >= 1024 ? (
        <div>{courseDesktop}</div>
      ) : (
        <div className="tabs-component pb-5">{courseMobile}</div>
      )}
    </>
  )
}

export default CourseDetails

CourseDetails.propTypes = {
  features: PropTypes.object,
  date: PropTypes.string
}
